import styled, { css } from 'styled-components';

export const Row = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;

  ${(props: { show: boolean }) => css`
    display: ${props.show ? "flex" : "none"};
  `}
`;

export const NoticeText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #abadae;
`;

export const TableAccounts = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeader = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const TableCell = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const TableCellRemove = styled.td`
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
`;

export const TableWrap = styled.div`
  margin: 50px;
  width: 100%;
  max-width: 640px
`;

export const RemoveButton = styled.button`
  margin: 5px;
  box-sizing: border-box;
  padding: 8px 16px;
  color: white;
  background-color: #D8000C;
  border-radius: 3px;
  border-width: 0;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #b1020b;
  }
`;

export const ButtonWrap = styled.div`
  width: 100%;
  max-width: 640px
`;

export const CancelButton = styled.button`
  box-sizing: border-box;
  padding: 10px 20px;
  color: #606060;
  background-color: rgb(235, 235, 235, 1);
  border-radius: 3px;
  border-width: 0;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: rgb(200, 200, 200, 1);
  }
`;