import * as React from 'react';
import { useState, useEffect, useRef, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { useHistory } from 'react-router-dom'
import { CurrentUserContext } from "../../contexts/currentUser";
import { Page } from '../page';
import { FormButton } from '../../components/buttons/button';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';
import {
  Row, NoticeText, FormBlock, FormItem, Title, TitleInput, ErrorMessage,
  SubmitButtonWrap, CancelButton, ImageWrap
} from './newBankAccount.styles';
import { useFormik, Field, FormikProvider } from 'formik';
import { iFieldStyleInit, iFieldStyleError, iFrameStyle, threeds } from './cardknoxOptions';
import IField, { ACH_TYPE } from 'react-cardknox-ifields';
import {endPoint} from "../../config.json";
import { AlertMesssage } from '../../components/blocks/alertMessage/alertMessage';

const NewBankAccountPage = (props:any) => {

  const [iFieldStyle, setIFieldStyle] = useState<any>(iFieldStyleInit);
  const [iFrameIsLoaded, setIFrameIsLoaded] = useState<boolean>(false);
  const [iFieldTouched, setIFieldTouched] = useState<any>(false);
  const [iFieldError, setIFieldError] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const tokenRef = useRef('');
  const inputReference = useRef(null);
  const [{ responses, errors }, doFetch] = useFetch('addBankAccount');
  const history = useHistory();
  const [currentUserState] = useContext(CurrentUserContext);

  const achOptions = { autoSubmit: false, enableLogging: false, iFieldstyle: iFieldStyle, iFrameStyle };

  // Formik declaration & submission
  const formik = useFormik({
    initialValues: { nickname: '', name: '', routing: '', type_checking: 1 },
    validate,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      await sleep(2000);
      const tokenData = tokenRef.current.split(";")
      tokenData[0] = tokenData[0].substring(tokenData[0].length - 4, tokenData[0].length);
      const data = { ...values, account: tokenData[0], token: tokenRef.current };
      if (!iFieldError)
        doFetch({ method: 'POST', data });
      else
        setIsSubmitting(false);
    }
  });

  useEffect(() => {
    if(!currentUserState.currentUser.settings) return;
    if (currentUserState.currentUser.settings.accept_ach != '1') {
      if (props.location.state && props.location.state.redirect)
        history.push(props.location.state.redirect);
      else
        history.push(`/payment`);
    }
  }, [currentUserState.currentUser])

  useEffect(() => {
    if (!currentUserState.isLoggedIn) return;
    formik.setFieldValue('name', `${currentUserState.currentUser.first_name} ${currentUserState.currentUser.last_name}`)
  }, [currentUserState.isLoggedIn])

  useEffect(() => {
    if (!responses) return;
    //console.log(props);
    setIsSubmitting(false);

    if (props.location.state && props.location.state.redirect)
      history.push(props.location.state.redirect);
    else
      history.push(`/payment`);
  }, [responses])

  useEffect(() => {
    if (!errors) return;
    setIsSubmitting(false);
    inputReference.current.clearIfield();
    formik.setFieldValue('routing', '');
  }, [errors])

  // Validate iField
  useEffect(() => {
    setIFieldStyle(iFieldTouched && iFieldError ? iFieldStyleError : iFieldStyleInit);
  }, [iFieldTouched, iFieldError]);

  // Validate on immidiate submit
  const validateIField = () => {
    if (!tokenRef.current) {
      setIFieldTouched(true);
      setIFieldError('Required');
    }
  }

  // Cardknox iFields user event methods
  const onLoad = () => setIFrameIsLoaded(true);

  const onUpdate = (data: any) => {
    if (data.isEmpty) setIFieldError('Required');
    else if (!data.isValid) setIFieldError('Must be a valid number');
    else setIFieldError(null);

    if (data.event === 'blur') setIFieldTouched(true);
  }

  const onToken = (data: any) => tokenRef.current = data.xToken;

  const onError = (data: any) => console.error("IFrame errored", data);

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  return (
    <Page title='New Bank Account' tabNavigation={true}>

      <TitledBlock title='Add Bank Account'>
        <NoticeText>
          For your protection, new bank accounts might go through verification
          process before you can use them to make payments.
        </NoticeText>
      </TitledBlock>
      {errors && <AlertMesssage type={'error'} message={errors.message}></AlertMesssage>}
      <Row show={iFrameIsLoaded}>
        <FormikProvider value={formik}>

          <FormBlock onSubmit={formik.handleSubmit}>

            <FormItem>
              <Title>Account Nickname *</Title>
              <TitleInput id="nickname" type="text" error={formik.touched.nickname && formik.errors.nickname} {...formik.getFieldProps('nickname')} />
              <ErrorMessage>{formik.touched.nickname && formik.errors.nickname ? formik.errors.nickname : null}</ErrorMessage>
            </FormItem>

            <FormItem>
              <Title>Account Holder Name *</Title>
              <TitleInput id="name" type="text" error={formik.touched.name && formik.errors.name} {...formik.getFieldProps('name')} />
              <ErrorMessage>{formik.touched.name && formik.errors.name ? formik.errors.name : null}</ErrorMessage>
            </FormItem>

            <FormItem>
              <Title>Account Number *</Title>
              <IField
                ref={inputReference}
                type={ACH_TYPE}
                account={currentUserState && currentUserState.currentUser.ikey}
                options={achOptions}
                threeDS={threeds}
                src={endPoint}
                onLoad={onLoad}
                onUpdate={onUpdate}
                onToken={onToken}
                onError={onError}
              />
              {iFieldTouched && iFieldError && <ErrorMessage>{iFieldError}</ErrorMessage>}
              <TitleInput type={'text'} value={tokenRef.current} hidden readOnly />
            </FormItem>

            <FormItem>
              <Title>Routing Number (9 digits) *</Title>
              <TitleInput id="routing" type="text" error={formik.touched.routing && formik.errors.routing} {...formik.getFieldProps('routing')} />
              <ErrorMessage>{formik.touched.routing && formik.errors.routing ? formik.errors.routing : null}</ErrorMessage>
            </FormItem>

            <FormItem>
              <Title>Account Type *</Title>
              <Field name="type_checking" as="select" style={{ width: "100%", height: "30px", marginTop: "10px", borderColor: "#ABB2B9" }}>
                <option value="1">Checking</option>
                <option value="0">Savings</option>
              </Field>
            </FormItem>

            <SubmitButtonWrap>
              <FormButton type="submit" onClick={validateIField} active={!isSubmitting} disabled={isSubmitting}>Add Account</FormButton>
              <CancelButton type="button" onClick={() => history.push(!props.location.state || props.location.state.gohome ? '/' : props.location.state.redirect)}>Cancel</CancelButton>
            </SubmitButtonWrap>

          </FormBlock>

          <ImageWrap>
            <img src={require('../../../assets/img/sampleCheck.png')} />
          </ImageWrap>

        </FormikProvider>
      </Row>

    </Page >
  );
}

const validate = (values: any) => {

  const errors: any = {};

  if (!values.name) errors.name = 'Required';
  else if (values.name.length < 4) errors.name = 'Must be at leats 5 characters';

  if (!values.nickname) errors.nickname = 'Required';
  else if (values.nickname.length < 4) errors.nickname = 'Must be at leats 5 characters';

  if (!values.routing) errors.routing = 'Required';
  else if (values.routing.length !== 9) errors.routing = 'Must be 9 digits';
  else if (!/^\d+$/.test(values.routing)) errors.routing = 'Must contain only numbers';

  return errors;
};

export default NewBankAccountPage;