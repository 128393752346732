import * as React from 'react';
import { Page } from '../page';
import {TitledBlock} from '../../components/blocks/titledBlock/titledBlock';
import { NoticeText, StageBlock, Title, TypeWrap, TypeBlock, TypeIcon,
  TypeName, TitleInput, DetailsTextArea, SubmitButtonWrap, PreviewsWrap, PreviewsBlock, RedText, FileError } from './newTicket.styles';
import { Button } from '../../components/buttons/button';
import { useHistory, Redirect } from 'react-router-dom';
import {useContext, useEffect, useState} from "react";
import useFetch from "../../hooks/useFetch";
import {FormWrapper} from "../../components/formik/formik.styles";
import {CurrentUserContext} from "../../contexts/currentUser";


export enum FileType{
    Image,
    Video
}
interface FileObject {
    file: File,
    preview: string,
    id: number,
    type: number
}

export const NewTicketPage = () => {
    const [stage, setStage] = useState<number>(1)
    const [type, setType] = useState<number>(null)
    const [title, setTitle] = useState<string>('')
    let fileCounter:number = 0;
    const [description, setDescription] = useState<string>('')
    const [images, setImages] = React.useState<Array<FileObject>>([])
    const [fileError, setFileError] = React.useState<string>('')
    const history = useHistory();
    const [{responses, errors, isLoading}, doFetch] = useFetch('addTicket');
    const [currentUserState] = useContext(CurrentUserContext);
    const videoSizeLimit = 300;
    const imageCountLimit = 10;

    const verifyFiles = (fileList: FileList, type: number) => {
        setFileError('');
        if (!fileList) return true;
        let initial = 0;
        for (let i = 0; i < images.length; i++) {
            if(type == images[i].type) {
                if(type == FileType.Image) initial++; else initial += images[i].file.size;
            }
        }
        for (let i = 0; i < fileList.length; i++) {
            if(type == FileType.Image) initial++; else initial += fileList[i].size;
        }
        if(type == FileType.Video && initial > videoSizeLimit * 1000000) {
            setFileError('Total size of uploaded video files exceeds '+videoSizeLimit+'Mb');
            return false;
        }
        if(type == FileType.Image && initial > imageCountLimit) {
            setFileError('You cannot upload more than '+imageCountLimit+' images.');
            return false;
        }
        return true;
    }

    const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if(!verifyFiles(fileList, FileType.Image)) return;
        let tempList: Array<FileObject> = [];
        if (!fileList) return;
        for (let i = 0; i < fileList.length; i++) {
            const obj:FileObject = {file: fileList[i], preview: URL.createObjectURL(fileList[i]), id: fileCounter++, type: FileType.Image};
            tempList.push(obj);
        }
        setImages(images.concat(tempList));
    };

    const handleVideoChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if(!verifyFiles(fileList, FileType.Video)) return;
        if (!fileList) return;
        const obj:FileObject = {file: fileList[0], preview: URL.createObjectURL(fileList[0]), id: fileCounter++, type: FileType.Video};
        setImages(images.concat([obj]));
    };

    const setTypeHandle = (type:number) => {
      setType(type)
      setStage(2)
    }

    const deleteFile = (id:number) => {
       setImages(images.filter(item => item.id !== id))
    }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('type', String(type));
        for (let i = 0 ; i < images.length ; i++) {
            if(images[i].type == FileType.Image) formData.append("images[]", images[i].file);
            else formData.append("videos[]", images[i].file);
        }
        doFetch({
            method: 'post',
            data:  formData ,
            ctype: "multipart/form-data"
        })
    }
    useEffect(() => {
        if (!responses) return;

        history.push({
            pathname: '/submit_confirmation',
            state: {
                number: responses.id
            },
        });
    }, [responses])

    return (
    <Page title='New Ticket' tabNavigation={true}>
        {currentUserState.currentUser && currentUserState.currentUser.activelease && currentUserState.currentUser.activelease.active === "0" && <Redirect to={"/"}/> }

        <TitledBlock title='New Maintenance Request'>
        <NoticeText>Answer the questions below to submit your request</NoticeText>
      </TitledBlock>

      {stage === 1 && renderFirstStage()}
      {stage === 2 && renderSecondStage()}
    </Page>
    );

    function renderFirstStage(): JSX.Element {
        return (
          <StageBlock>
            <Title style={{marginLeft:"4%"}}>1. What type of problem are you having?</Title>
            <TypeWrap>
              <TypeBlock onClick={() => setTypeHandle(1)}>
                <TypeIcon icon={require('../../../assets/icons/drop.svg')}/>
                <TypeName>Plumbing</TypeName>
              </TypeBlock>
              <TypeBlock onClick={() => setTypeHandle(2)}>
                <TypeIcon icon={require('../../../assets/icons/electricity.svg')}/>
                <TypeName>Electric</TypeName>
              </TypeBlock>
              <TypeBlock onClick={() => setTypeHandle(3)}>
                <TypeIcon icon={require('../../../assets/icons/appliances.svg')}/>
                <TypeName>Appliances</TypeName>
              </TypeBlock>
              <TypeBlock onClick={() => setTypeHandle(4)}>
                <TypeIcon icon={require('../../../assets/icons/heatair.svg')}/>
                <TypeName>Heat/Air</TypeName>
              </TypeBlock>
              <TypeBlock onClick={() => setTypeHandle(5)}>
                <TypeIcon icon={require('../../../assets/icons/keys.svg')}/>
                <TypeName>Locks & Key</TypeName>
              </TypeBlock>
              <TypeBlock onClick={() => setTypeHandle(6)}>
                <TypeIcon icon={require('../../../assets/icons/pest.svg')}/>
                <TypeName>Pest</TypeName>
              </TypeBlock>
              <TypeBlock onClick={() => setTypeHandle(7)}>
                <TypeIcon icon={require('../../../assets/icons/home.svg')}/>
                <TypeName>Other</TypeName>
              </TypeBlock>
            </TypeWrap>
          </StageBlock>
        );
    }

    function renderSecondStage(): JSX.Element {
    return (
      <StageBlock>
          <FormWrapper>
            <Title>2. Give us a brief description of the problem:</Title>
            <TitleInput type={'text'} placeholder={'Request Title'} value={title} onChange={e => setTitle(e.target.value)}/>
              <br/><br/>
            <Title>3. Additional details: (the more the better)</Title>
            <DetailsTextArea value={description} placeholder={'Request Details'}
                             onChange={e => setDescription(e.target.value)}/>
          </FormWrapper>
        <PreviewsWrap>
            {images.map((image, i) => {
                return (<PreviewsBlock>{images[i].preview == null && <span>Loading preview...</span>}
                    {images[i].type == FileType.Image ?
                        <img src={images[i].preview} /> :
                        <video width="100%" controls><source src={images[i].preview}/></video>} <span onClick={() => deleteFile(images[i].id)}>x</span></PreviewsBlock>)
            })}
        </PreviewsWrap>
          {fileError && <FileError>{fileError}</FileError>}
        <TypeWrap>
          <TypeBlock htmlFor='photo'>
            <TypeIcon icon={require('../../../assets/icons/camera.svg')}/>
            <TypeName>Add photo</TypeName>
          </TypeBlock>
          <TypeBlock htmlFor='video'>
            <TypeIcon icon={require('../../../assets/icons/video.svg')}/>
            <TypeName>Add Video</TypeName>
          </TypeBlock>
        </TypeWrap>
          <input
              accept="image/*"
              style={{ display: "none" }}
              id="photo"
              name="photo"
              type="file"
              multiple={true}
              onChange={handleImageChange}
          />
          <input
              accept="video/*"
              style={{ display: "none" }}
              id="video"
              name="video"
              type="file"
              multiple={false}
              onChange={handleVideoChange}
          />
          <RedText>(Limit: 10 images, total video size &lt; 300mb)</RedText>
        <SubmitButtonWrap>
          <Button onClick={handleSubmit}>SUBMIT</Button>
        </SubmitButtonWrap>
      </StageBlock>
    );
  }
}
