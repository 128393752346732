import * as React from 'react';
import { TabLink } from './tab.styles';
export interface TabInterface {
  iconURL: number;
  href: string;
}

interface Props {
  data: TabInterface;
}

function Icon1() {
  return <svg xmlns="http://www.w3.org/2000/svg"  height="32" viewBox="0 0 48 48" width="32"><path d="M0 0h48v48H0z" fill="none"/>
    <g id="Shopicon" fill="white">
      <path d="M33.843,26.914L24,36l-9.843-9.086C8.674,30.421,5,36.749,5,44h38C43,36.749,39.326,30.421,33.843,26.914z"/>
      <path d="M24,28c3.55,0,6.729-1.55,8.926-4C34.831,21.876,36,19.078,36,16c0-6.627-5.373-12-12-12S12,9.373,12,16
		c0,3.078,1.169,5.876,3.074,8C17.271,26.45,20.45,28,24,28z"/>
    </g>
  </svg>
}

function Icon2() {
  return <svg  version="1.1" height="32" width="32" viewBox="0 -256 1792 1792" fill="none">
    <g fill="white" transform="matrix(1,0,0,-1,53.152542,1217.0847)">
      <path d="m 384,64 q 0,26 -19,45 -19,19 -45,19 -26,0 -45,-19 -19,-19 -19,-45 0,-26 19,-45 19,-19 45,-19 26,0 45,19 19,19 19,45 z m 644,420 -682,-682 q -37,-37 -90,-37 -52,0 -91,37 L 59,-90 Q 21,-54 21,0 21,53 59,91 L 740,772 Q 779,674 854.5,598.5 930,523 1028,484 z m 634,435 q 0,-39 -23,-106 Q 1592,679 1474.5,595.5 1357,512 1216,512 1031,512 899.5,643.5 768,775 768,960 q 0,185 131.5,316.5 131.5,131.5 316.5,131.5 58,0 121.5,-16.5 63.5,-16.5 107.5,-46.5 16,-11 16,-28 0,-17 -16,-28 L 1152,1120 V 896 l 193,-107 q 5,3 79,48.5 74,45.5 135.5,81 61.5,35.5 70.5,35.5 15,0 23.5,-10 8.5,-10 8.5,-25 z"/>
    </g>
  </svg>
}

function Icon3() {
  return <svg xmlns="http://www.w3.org/2000/svg"  height="32" viewBox="0 0 48 48" width="32"><path d="M0 0h48v48H0z" fill="none"/>
    <g id="Shopicon">
      <path fill="white" d="M8.706,37.027c2.363-0.585,4.798-1.243,6.545-1.243c0.683,0,1.261,0.101,1.688,0.345c1.474,0.845,2.318,4.268,3.245,7.502
		C21.421,43.866,22.694,44,24,44c1.306,0,2.579-0.134,3.816-0.368c0.926-3.234,1.771-6.657,3.244-7.501
		c0.427-0.245,1.005-0.345,1.688-0.345c1.747,0,4.183,0.658,6.545,1.243c1.605-1.848,2.865-3.99,3.706-6.333
		c-2.344-2.406-4.872-4.891-4.872-6.694c0-1.804,2.528-4.288,4.872-6.694c-0.841-2.343-2.101-4.485-3.706-6.333
		c-2.363,0.585-4.798,1.243-6.545,1.243c-0.683,0-1.261-0.101-1.688-0.345c-1.474-0.845-2.318-4.268-3.245-7.502
		C26.579,4.134,25.306,4,24,4c-1.306,0-2.579,0.134-3.816,0.368c-0.926,3.234-1.771,6.657-3.245,7.501
		c-0.427,0.245-1.005,0.345-1.688,0.345c-1.747,0-4.183-0.658-6.545-1.243C7.101,12.821,5.841,14.962,5,17.306
		C7.344,19.712,9.872,22.196,9.872,24c0,1.804-2.527,4.288-4.872,6.694C5.841,33.037,7.101,35.179,8.706,37.027z M18,24
		c0-3.314,2.686-6,6-6s6,2.686,6,6s-2.686,6-6,6S18,27.314,18,24z"/>
    </g>
  </svg>
}

export class Tab extends React.Component<Props, {}> {
  public render() {
    return (
      <TabLink to={this.props.data.href}>
        {this.props.data.iconURL == 1 && Icon1()}
        {this.props.data.iconURL == 2 && Icon2()}
        {this.props.data.iconURL == 3 && Icon3()}
      </TabLink>
    );
  }
}
