import * as React from 'react';
import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import { useHistory } from 'react-router-dom';
import { Page } from '../page';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';
import {
  Row, NoticeText, TableWrap, TableAccounts, TableHeader,
  TableCell, TableCellRemove, RemoveButton, ButtonWrap, CancelButton
} from './editAccounts.styles';

const EditAccountsPage = () => {

  const [accounts, setAccounts] = useState<any>([]);
  const [accountsLoaded, setAccountsLoaded] = useState<boolean>(false);
  const [accountsResult, fetchAccounts] = useFetch('getBankAccounts');
  const [removeAccountResult, removeAccount] = useFetch('removeAccount');
  const history = useHistory();

  useEffect(() => {
    fetchAccounts({ method: 'get' })
  }, []);

  useEffect(() => {
    if (!accountsResult.responses) return;

    if (!accountsResult.responses.count) {
      console.log(`There's no accounts on file`);
      return;
    }
    setAccounts(accountsResult.responses.accounts);
    setAccountsLoaded(true);

  }, [accountsResult.responses]);

  const handleRemoveAccount = ((id: number) => {
    removeAccount({ method: 'POST', data: { bank_account: id } });
  })

  useEffect(() => {
    if (!removeAccountResult.responses) return;
    history.push(`/payment`)
  }, [removeAccountResult.responses]);

  const accountTable: any = accounts.map((acc: any, i: number) => {
    return (
      <tr key={i}>
        <TableCell key={`${acc.nickname}-${i}`}>{acc.nickname}</TableCell>
        <TableCell key={`${acc.account}-${i}`}>***{acc.account}</TableCell>
        <TableCell key={`${acc.cc}-${i}`}>{Number(acc.cc) ? 'Credit Card' : 'Bank Account'}</TableCell>
        <TableCellRemove key={`remove-${i}`}>
          <RemoveButton onClick={() => handleRemoveAccount(Number(acc.id))}>Remove</RemoveButton>
        </TableCellRemove>
      </tr>
    )
  });


  return (
    <Page title='New Bank Account' tabNavigation={true}>

      <TitledBlock title='Edit Payment Methods'>
        <NoticeText>
          View and remove your saved bank accounts and credit cards on file.
        </NoticeText>
      </TitledBlock>

      {accounts.length > 0 && <Row show={accountsLoaded}>
        <TableWrap>
            <TableAccounts>
              <thead>
                <tr key='header'>
                  <TableHeader key='nickname'>Nickname</TableHeader>
                  <TableHeader key='account'>Account Number</TableHeader>
                  <TableHeader key='type'>Type</TableHeader>
                  <TableHeader key='remove'>Action</TableHeader>
                </tr>
              </thead>
              <tbody>
                {accountTable}
              </tbody>
            </TableAccounts>

        </TableWrap>
      </Row> }
        {accounts.length == 0 && <div><br/>
          <Row show={true}>There is no accounts added.</Row><br/></div>
        }
      <Row show={accountsLoaded}>
        <ButtonWrap>
          <CancelButton type="button" onClick={() => history.push(`/payment`)}>Cancel</CancelButton>
        </ButtonWrap>
      </Row>

    </Page >
  );
};



export default EditAccountsPage;